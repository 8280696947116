import JsonApiResource from './JsonApiResource'
import AnearZone from './AnearZone'
import AnearUser from './AnearUser'

const BroadcastMessageType       = 'BROADCAST'
const PublicDisplayMessageType   = 'PUBLIC_DISPLAY'
const EventTransitionMessageType = 'EVENT_TRANSITION'
const PlayAgainMessageType       = 'PLAY_AGAIN'

export default class AnearEvent extends JsonApiResource {
  // represents the state of the event at time of fetch
  // from the Anear API.  Ably event transition messages can
  // change the live state kept in the EventPage state.
  //
  constructor(json_data, json_included) {
    super(json_data, json_included)
    this.zone = new AnearZone(this.findIncluded(this.relationships['zone']), this.included)
    this.user = new AnearUser(this.findIncluded(this.relationships['user']), this.included)
    this._userMessaging = null
    this._channels =  {
      eventChannel: null,
      spectatorsChannel: null
    }
  }

  get name() {
    return this.attributes.name
  }

  get slug() {
    return this.attributes.slug
  }

  get short_url() {
    return `/e/${this.slug}`
  }

  get state() {
    return this.attributes.state
  }

  get cssUrl() {
    return this.attributes['css-url']
  }

  get context() {
    return this.attributes.context
  }

  get hosted() {
    // A hosted event possibly has a participant who only acts as the moderator or "host".
    // He/she typically does not participate in game play.  E.g.  Trivia contest organizer and master of ceremonies.
    // Or, a merchnat sponsoring an event at his place of business
    return this.attributes.hosted
  }

  getEventState = () => {
    return {
      state: this.state,
      context: this.context
    }
  }

  get canPlayAgain() {
    return this.zone.canPlayAgain
  }

  requiresGeoLocationToParticipate = () => {
    // if the event has a participation radius, then a participant must
    // have a geoLocation set in the browser for validation by the Anear API
    return this.attributes['participation-radius']
  }

  spectatorsAllowed = () => {
    return !this.attributes.flags.includes("no_spectators")
  }

  attachedToSpectatorsChannel = () => {
    const sc = this._channels.spectatorsChannel
    return sc && sc.state === 'attached'
  }

  initEventChannels = () => {
    if (!this._channels.eventChannel) {
      this._channels.eventChannel = this._userMessaging.getChannel(this.attributes['event-channel-name'])
    }
  }

  initMessaging = (userMessaging) => {
    this._userMessaging = userMessaging
    this.initEventChannels()
  }

  initSpectatorMessaging = () => {
    if (this.spectatorsAllowed() && !this._channels.spectatorsChannel) {
      this._channels.spectatorsChannel = this._userMessaging.getChannel(this.attributes['spectators-channel-name'])
    }
  }

  detachAll = async () => {
    const detachPromises = []

    if (this._channels.eventChannel) {
      detachPromises.push(this._userMessaging.detachChannel(this._channels.eventChannel))
    }

    // Add the closeSpectatorsChannel() call unconditionally since it doesn't have a null check
    detachPromises.push(this.closeSpectatorsChannel())

    // Await all non-null detach operations
    await Promise.all(detachPromises)

    // Set channels to null after detaching
    this._channels.eventChannel = null
  }
  
  closeMessaging = async () => {
    await this.detachAll()
  }

  subscribeEventBroadcastMessages = callback => {
    this._userMessaging.subscribeEventMessages(this._channels.eventChannel, BroadcastMessageType, callback)
  }

  subscribeEventTransitionMessages = callback => {
    this._userMessaging.subscribeEventMessages(this._channels.eventChannel, EventTransitionMessageType, callback)
  }

  subscribeEventPlayAgainMessages = callback => {
    this._userMessaging.subscribeEventMessages(this._channels.eventChannel, PlayAgainMessageType, callback)
  }

  subscribeSpectatorDisplayMessages = async (anearUser, displayCallback) => {
    this.initSpectatorMessaging()
    this._userMessaging.subscribeEventMessages(this._channels.spectatorsChannel, PublicDisplayMessageType, displayCallback, false)
    await this._userMessaging.setChannelPresence(this._channels.spectatorsChannel, anearUser.id)
  }

  unSubscribeEventBroadcastMessages = () => {
    this._userMessaging.unSubscribeEventMessages(this._channels.eventChannel, BroadcastMessageType)
  }

  unSubscribeEventTransitionMessages = () => {
    this._userMessaging.unSubscribeEventMessages(this._channels.eventChannel, EventTransitionMessageType)
  }

  unSubscribeEventPlayAgainMessages = () => {
    this._userMessaging.unSubscribeEventMessages(this._channels.eventChannel, PlayAgainMessageType)
  }

  closeSpectatorsChannel = async () => {
    if (!this._channels.spectatorsChannel) return 

    this._userMessaging.unSubscribeEventMessages(this._channels.spectatorsChannel, PublicDisplayMessageType)
    await this._userMessaging.detachChannel(this._channels.spectatorsChannel)
    this._channels.spectatorsChannel = null
  }
}
