import * as logger from 'loglevel'
import React from 'react'
import { Link, Navigate } from 'react-router-dom'
import List from '@mui/material/List'
import Box from '@mui/material/Box'
import ListItem from '@mui/material/ListItem'
import EventCard from '../UI/EventCard'

import EventsContainer from '../Containers/EventsContainer'
import ROUTES from '../../routesConfig'

const EventList = ({ playableEvents }) => {
  const eventLinks = playableEvents => {
    return (
      playableEvents.map(playableEvent => {
        const anearEvent = playableEvent.anearEvent
        logger.info(`event ${anearEvent.name} is in the '${playableEvent.playableState}' playable state`)
        return (
          <Link sx={{textDecoration: 'none'}} to={ROUTES.EVENT_PAGE(anearEvent.slug)} key={anearEvent.id} >
            <ListItem>
              <EventCard playableEvent={playableEvent} />
            </ListItem>
          </Link>
        )
      })
    )
  }

  return (
    <Box
      sx={{
        width: 'auto',
        display: 'block', // Fix IE 11 issue.
        ml: 1,
        mr: 1
      }}
    >
      <List dense={true}>
        {eventLinks(playableEvents)}
      </List>
    </Box>
  )
}

const EventsPage = () => {
  const eventsContainer = EventsContainer.useContainer()

  if (eventsContainer.state.playableEvents.length === 0) {
    return <Navigate to={{pathname: ROUTES.APPS}} replace />
  } else {
    return (
      <EventList playableEvents={eventsContainer.state.playableEvents}/>
    )
  }
}

export default EventsPage
