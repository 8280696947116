import React from 'react'
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react'
import UserAuthContainer from '../Containers/UserAuthContainer'
import { Box } from '@mui/material'

const mapStyles = {
  width: 175,
  height: 175,
}

const DefaultZoom = 18

const LoadingContainer = () => (
  <div>loading...</div>
)

const UserLocationMap = ({ zoom, google }) => {
  const userAuth = UserAuthContainer.useContainer()

  const lat = parseFloat(userAuth.currentLat())
  const lng = parseFloat(userAuth.currentLong())

  return (
    lat ? (
      <Box
        sx={{
          marginLeft: "-6px",
          marginTop: 1,
          width: mapStyles.width,
          height: mapStyles.height,
        }}
      >
        <Map
          google={google}
          zoom={zoom || DefaultZoom}
          style={mapStyles}
          disableDefaultUI={true}
          initialCenter={{
            lat,
            lng,
          }}
        >
          <Marker name={'Current location'} />
        </Map>
      </Box>
    ) : (
      <Box sx={{ margin: "10px" }}>location not available</Box>
    )
  )
}

export default GoogleApiWrapper({
  apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  LoadingContainer: LoadingContainer
})(UserLocationMap)
