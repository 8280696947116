import * as logger from 'loglevel'
import React, { useState } from 'react'
import QrReader from 'react-qr-reader'
import Paper from '@mui/material/Paper';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import CardActionArea from '@mui/material/CardActionArea';
import Typography from '@mui/material/Typography';
import useNavigation from '../Utils/navigationUtils'
import ROUTES from '../../routesConfig'

// Helper function to escape regex special characters except for [0-9a-zA-Z]{9}
const escapeRegExp = (string) => {
  return string.replace(/[.*+?^${}()|[\]\\/]/g, '\\$&')
}

// Placeholder for the dynamic part of the URL
const placeholder = ':eventSlug'
// Slug pattern for matching 9 alphanumeric characters
const slugPattern = '([0-9a-zA-Z]{9})'

const regexpForEventPage = escapeRegExp(
  ROUTES.EVENT_PAGE(placeholder)
).replace(escapeRegExp(placeholder), slugPattern)

// Create a regex pattern dynamically for the QR code validation
const ValidQRPattern = new RegExp(
  `^https?:\\/\\/(?:go\\.)?anear\\.me${regexpForEventPage}$`
)


const QRScanWindow = ({ onBadScan, onEmptyScan }) => {

  const validQRCodeURL = url => ValidQRPattern.test(url)
  const eventSlug = route => route.match(/\/([^/]+)$/)[1] // trailing param in route

  const { navigateToEvent } = useNavigation()
 
  const handleScan = data => {
    if (data) {
      logger.info(`scanned: ${data}`)

      if (validQRCodeURL(data)) {
        navigateToEvent(eventSlug(data))
      } else {
        onBadScan()
      }
    } else {
      onEmptyScan()
    }
  }

  const handleError = err => logger.error(err)

  const scannerStyle = {
    marginTop: "10px",
    width: '100%'
  }

  return (
    <Paper square elevation={3}>
      <QrReader
        style={scannerStyle}
        delay={300}
        onError={handleError}
        onScan={handleScan}
      />
    </Paper>
  )
}

const ScanPage = _ => {
  const { navigateToApps } = useNavigation()
  const [ error, setError ] = useState(null)

  const onClose = () => {
    navigateToApps()
  }

  const onBadScan = () => {
    setError("Invalid QR Code")
  }

  const onEmptyScan = () => {
    setError(null)
  }

  return (
    <Card>
      <CardActionArea>
        <CardContent>
          <Paper square elevation={3}>
            <Typography
              variant="h6"
              align="center"
              color="secondary"
              sx={{
                mt: 1,
                ml: 1,
                mr: 1
              }}
            >
              {"Scan QR Code to Join an Event"}
            </Typography>
            <Typography
              sx={{
                ml: 1,
                mr: 1,
                mt: 0.5,
                fontWeight: 'bold',
                height: "2em"
              }}
              variant="subtitle1"
              align="center"
              color="error"
            >
              { error }
            </Typography>
          </Paper>

          <QRScanWindow onBadScan={onBadScan} onEmptyScan={onEmptyScan}/>

        </CardContent>
      </CardActionArea>
      <CardActions>
        <Button
          size="small"
          color="primary"
          onClick={onClose}
        >
          Close
        </Button>
      </CardActions>
    </Card>
  )
}

export default ScanPage
