import { useNavigate, Navigate } from 'react-router-dom'
import ROUTES from '../../routesConfig'

const useNavigation = () => {
  const navigate = useNavigate()

  const navigateToPathWithReplace = (path, replace = true) => {
    navigate(path, { replace })
  }

  // Navigate to specific routes
  const navigateToApps = () => {
    navigateToPathWithReplace(ROUTES.APPS)
  }

  const navigateToLogin = () => {
    navigate(ROUTES.LOGIN)
  }

  const navigateToSignup = () => {
    navigate(ROUTES.SIGNUP)
  }

  const navigateToProfile = () => {
    navigate(ROUTES.PROFILE)
  }

  const navigateToUpdateProfile = () => {
    navigateToPathWithReplace(ROUTES.UPDATE_PROFILE)
  }

  const navigateToScan = () => {
    navigate(ROUTES.SCAN)
  }

  const navigateToHome = () => {
    navigate(ROUTES.HOME)
  }

  const navigateToEventParticipant = (eventId, options = {}) => {
    navigate(ROUTES.EVENT_PARTICIPANT(eventId), { state: { ...options } })
  }

  const navigateToEvent = (eventSlug) => {
    navigateToPathWithReplace(ROUTES.EVENT_PAGE(eventSlug))
  }

  const navigateToCreateEvent = (zoneId) => {
    navigate(ROUTES.CREATE_EVENT(zoneId))
  }

  const navigateToEventPage = (shortUrl) => {
    navigateToPathWithReplace(shortUrl)
  }

  const navigateToScanPage = () => {
    navigateToPathWithReplace(ROUTES.SCAN)
  }

  // Function to return a JSX component for navigation
  const getNavigateComponent = (path, replace = true) => {
    return <Navigate to={path} replace={replace} />
  }

  const getNavigateToEventComponent = (eventId) => {
    return getNavigateComponent(ROUTES.EVENT_PAGE(eventId))
  }

  // Add more navigation functions as needed based on your specific routes and usage patterns

  return {
    navigateToApps,
    navigateToLogin,
    navigateToSignup,
    navigateToProfile,
    navigateToUpdateProfile,
    navigateToScan,
    navigateToHome,
    navigateToEventParticipant,
    navigateToEvent,
    navigateToCreateEvent,
    navigateToEventPage,
    navigateToScanPage,
    navigateToPathWithReplace,
    getNavigateComponent,
    getNavigateToEventComponent
  }
}

export default useNavigation
