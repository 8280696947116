import * as logger from 'loglevel'
import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'

import { Card, Box, CardContent } from '@mui/material'

import EventFrame from './EventFrame'
import anearApi from '../Api/AnearApi'

import EventsContainer from '../Containers/EventsContainer'
import UIStateContainer from '../Containers/UIStateContainer'
import useNavigation from '../Utils/navigationUtils'

const getAppStyleElement = anearEvent => {
  const appStyleId = `style-${anearEvent.zone.app.id}`
  return {
    appStyle: document.getElementById(appStyleId),
    appStyleId
  }
}

const removeAppCss = anearEvent => {
  const { appStyle, appStyleId } = getAppStyleElement(anearEvent)

  if (appStyle) {
    logger.info(`removing existing style with id=${appStyleId}`)
    appStyle.parentNode.removeChild(appStyle)
  }
}

const ensureAppCss = anearEvent => {
  const { appStyle, appStyleId } = getAppStyleElement(anearEvent)

  if (!appStyle) {
    // If no appStyle exists, create a new link element
    const newAppStyle = document.createElement("link")
    newAppStyle.setAttribute("id", appStyleId)
    newAppStyle.setAttribute("rel", "stylesheet")
    newAppStyle.setAttribute("href", anearEvent.cssUrl)
    document.head.appendChild(newAppStyle)

    logger.info(`set CSS styles for styleId ${appStyleId}`)
  } else {
    // If appStyle exists, check if the href is different from the new cssUrl
    if (appStyle.getAttribute("href") !== anearEvent.cssUrl) {
      appStyle.setAttribute("href", anearEvent.cssUrl)
      logger.info(`CSS updated for styleId ${appStyleId} with new href: ${anearEvent.cssUrl}`)
    } else {
      logger.info(`CSS for styleId ${appStyleId} is already up to date.`)
    }
  }
}

const EventPage = () => {
  // route handler for /e/<eventSlug>
  //   - render outer event container with exit controls
  //   - subscribes to eventsChannel broadcast and transition message types
  //   - handler for event transition triggered by external App
  //   - handler for event broadcast messages (Not yet functional)
  //   - click handlers for events that could come from within EventFrame:
  //      - JOIN   become participant)
  //      - BACK   exit the event as participant or spectator
  //      - REPLAY play the event again when event is no longer in progress
  //   - when event is requested to play again by a prev participant, handles
  //     cloning event
  //   - cleanup when unmounting, handles unsubscribing and detach of messaging
  //       for the participant and Event
  //
  const eventsContainer = EventsContainer.useContainer()
  const uiStateContainer = UIStateContainer.useContainer()

  const { eventSlug } = useParams()
  const { navigateToEventParticipant, navigateToEventPage, navigateToApps } = useNavigation()
  
  const setError = useState(null)[1]
  const setBroadcastMessages = useState([])[1]

  const anearEvent = eventsContainer.getEventFromSlug(eventSlug)
  const [participant, setParticipant] = useState(anearEvent ? eventsContainer.getParticipantForEvent(anearEvent.id) : null)

  const eventId = anearEvent ? anearEvent.id : null
  const eventState = anearEvent ? eventsContainer.getEventState(eventId) : null

  const broadcastMessageHandler = message => {
    setBroadcastMessages((prevBroadcastMessages) => [
      ...prevBroadcastMessages,
      message.data
    ])
  }

  const eventTransitionHandler = async message => {
    // when the app transitions an inProgress Event:
    //   - announcing to live
    //   - live to closing/closed
    // this handler can take any actions needed on the EventPage
    const newState = message.data.content.state
    logger.info(`Event '${eventId}' state transition change to ${newState} via app`)

    if (!eventsContainer.needsEventRefresh(eventId, newState)) return

    await eventsContainer.reloadEventWithProgress(eventId)
    const anearEvent = eventsContainer.getEvent(eventId)
    const eventState = eventsContainer.getEventState(eventId)

    if (eventState.isPlayAgainInvitable()) {
      anearEvent.subscribeEventPlayAgainMessages(invitedToPlayAgainHandler)
    }
  }

  const subscribeRequiredEventMessages = () => {
    if (anearEvent && eventState.isInProgress()) {
      anearEvent.subscribeEventBroadcastMessages(broadcastMessageHandler)
      anearEvent.subscribeEventTransitionMessages(eventTransitionHandler)
    }
  }

  // Ensure CSS when component is mounted
  useEffect(() => {
    if (anearEvent) {
      ensureAppCss(anearEvent)  // Ensure CSS when anearEvent is available
    }
  }, [anearEvent])  // Only re-run if anearEvent changes

  useEffect(() => {
    const cleanupEvent = async () => {
      if (!anearEvent) return 

      logger.info(`event ${anearEvent.id} cleanup().`)

      anearEvent.unSubscribeEventBroadcastMessages()
      anearEvent.unSubscribeEventTransitionMessages()
      anearEvent.unSubscribeEventPlayAgainMessages()

      if (participant) {
        await participant.closeMessaging()
      }
      await anearEvent.closeMessaging()
      await eventsContainer.deleteEvent(anearEvent.id)
      removeAppCss(anearEvent)
    }

    return () => cleanupEvent()
  }, [participant, anearEvent, eventsContainer])

  const requestPlayAgainHandler = async () => {
    const anearEvent = eventsContainer.getEvent(eventId)
    logger.info(`request to play event ${eventId} again`)
    await eventsContainer.removeParticipant(eventId)
    uiStateContainer.startProgress()

    try {
      const clonedEvent = await anearApi.cloneEvent(anearEvent)
      await cloneEventSuccess(clonedEvent)
    } catch (error) {
      cloneEventFailure(error)
    }
  }

  const invitedToPlayAgainHandler = async (message) => {
    const eventId = message.data.content.event_id
    const anearEvent = eventsContainer.getEvent(eventId)

    if (!anearEvent) return

    anearEvent.unSubscribeEventPlayAgainMessages(invitedToPlayAgainHandler)

    const eventState = eventsContainer.getEventState(eventId)

    if (eventState.isParticipant()) {
      await eventsContainer.removeParticipant(eventId)
      logger.info(`received request to play again in event ${eventId}.`)
      navigateToEventParticipant(eventId, { playAgain: true })
    } else {
      logger.info(`spectator view play_again event ${eventId}.`)
      navigateToEventPage(anearEvent.short_url)
    }
  }

  const cloneEventSuccess = async clonedEvent => {
    logger.info(`clone event success for ${clonedEvent.id}!`)
    eventsContainer.addNewEvent(clonedEvent)

    try {
      if (!clonedEvent.hosted) {
        const anearParticipant = await anearApi.becomeEventParticipant(clonedEvent)
        await eventsContainer.addNewParticipant(anearParticipant)
      }
      await uiStateContainer.progressComplete()
      navigateToEventPage(clonedEvent.shortUrl)
    } catch (err) {
      await uiStateContainer.progressComplete()
      cloneEventFailure(err)
    }
  }

  const cloneEventFailure = error => {
    logger.error(error)
    setError(error)
  }

  const stopParticipating = async () => {
    const anearParticipant = eventsContainer.getParticipantForEvent(eventId)
    if (anearParticipant) await anearParticipant.publishExitEvent()
  }

  const cancelClickHandler = async eventState => {
    if (eventState.isCreator() || eventState.isParticipant()) {
      await stopParticipating()
      if (eventState.isCreator() && eventState.isOpening()) {
        await eventsContainer.removeEventAndParticipant(eventId)
      } else {
        await eventsContainer.removeParticipant(eventId)
      }
    }
    navigateToApps()
  }

  const eventParticipantFailure = error => {
    logger.error(error)
    setError(error)
  }

  const eventParticipantHandler = async () => {
    uiStateContainer.startProgress()

    try {
      const anearEvent = eventsContainer.getEvent(eventId)
      const anearParticipant = await anearApi.becomeEventParticipant(anearEvent)
      await eventsContainer.addNewParticipant(anearParticipant)
      await uiStateContainer.progressComplete()
      setParticipant(anearParticipant)
    } catch (errMsg) {
      await uiStateContainer.progressComplete()
      eventParticipantFailure(errMsg)
    }
  }

  const eventClickHandlers = async (e, command) => {
    await browserClickHandlers(e, command, eventState)
  }

  const browserClickHandlers = async (e, action, eventState = null) => {
    switch (action) {
      case "cancel":
      case "back":
        cancelClickHandler(eventState)
        break
      case "replay":
        await requestPlayAgainHandler()
        break
      case "join":
        await eventParticipantHandler()
        break
      default:
        throw new Error(`unknown browserClick command=${action}`)
    }
  }

  subscribeRequiredEventMessages()

  return (
    <Box
      sx={{
        width: 'auto',
        display: 'block',
        alignItems: 'center',
        flexDirection: 'column',
        px: 1,
        py: 1
      }}
    >
      {anearEvent && (
        <Card
          sx={{
            zIndex: 1,
            position: 'relative',
            m: 1
          }}
        >
          <CardContent
            sx={{
              p: 1
            }}
          >
            <EventFrame
              anearEvent={anearEvent}
              eventClickHandlers={eventClickHandlers}
              participant={participant}
            />
          </CardContent>
        </Card>
      )}
    </Box>
  )
}

export default EventPage
