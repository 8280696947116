import * as logger from 'loglevel'
import React, { useRef, useEffect, useState, useCallback } from 'react'
import { Box } from '@mui/material'
import DOMPurify from 'dompurify'
import UserAuthContainer from '../Containers/UserAuthContainer'
import useAppDisplayDomSetup from '../Hooks/useAppDisplayDomSetup'

const AnearActionClick = 'anear-action-click'
const AnearActionForm  = 'anear-action-form'
const AnearBrowserClick = 'anear-browser-click'

const NotClickable = 'null'
const DefaultFrameContent = "<p>&nbsp;&nbsp;&nbsp;loading app...</p>"

const ClickUrl = `${process.env.REACT_APP_ANEAR_APP_ASSETS_URL}/click.wav`
const AudibleClick = new Audio(ClickUrl)

const audibleClick = async (e, handler) => {
  handler(e)
  await AudibleClick.play()
}

const AppDisplay = React.memo(
  ({ anearEvent, frameContent, frameContentRef, lastMessageId, userAuthContainer}) => {
    const safeHTML = DOMPurify.sanitize(frameContent)

    useAppDisplayDomSetup(anearEvent, frameContentRef, userAuthContainer)

    return (
      <Box sx={{ width: 'auto', height: '560px', ml: 1 }}>
        <div
          id={anearEvent.id}
          ref={frameContentRef}
          dangerouslySetInnerHTML={{ __html: safeHTML }}
        />
      </Box>
    )
  }, (prevProps, nextProps) => {
    // This function determines if AppDisplay should re-render.
    // Returning true if props are equal (no re-render), or false if props are different (trigger re-render).
    return prevProps.lastMessageId === nextProps.lastMessageId
  }
)

const getActionFormMessage = form => {
  const inputs = form.querySelectorAll('input:not([type=submit]), select, textarea')
  const formValues = {}

  for (let i = 0; i < inputs.length; i++) {
    if (inputs[i].tagName === 'SELECT') {
      formValues[inputs[i].id] = [...inputs[i].options]
        .filter(option => option.selected)
        .map(option => option.value)
    } else  {
      formValues[inputs[i].id] = inputs[i].value
    }
  }

  const eventName = form.getAttribute(AnearActionForm)

  return JSON.stringify(
    {
      [eventName]: formValues
    }
  )
}

const EventFrame = ({anearEvent, eventClickHandlers, participant}) => {
  //
  // The EventFrame interacts with Ably Channels API to receive display
  // content from App servers for rendering... and accepts user inputs for relay back to
  // app servers
  //
  const frameContentRef = useRef(null)

  const [frameContent, setFrameContent] = useState(DefaultFrameContent)
  const [lastMessageId, setLastMessageId] = useState(null)

  const userAuthContainer = UserAuthContainer.useContainer()

  const displayMessageHandler = useCallback(
    message => {
      setLastMessageId(message.id)
      setFrameContent(message.data)
    }, [setLastMessageId, setFrameContent]
  )

  const displayParticipantMessageHandler = useCallback(
    message => {
      logger.info(`participant message rcvd: ${message.name}`)
      displayMessageHandler(message)
    }, [displayMessageHandler]
  )

  const setParticipantDisplayHandler = useCallback(
    async () => {
      if (!participant.isHost()) {
        // Do not subscribe to participant messages if we are the event host
        // That way, when the App publishes to this channel, the Event Host
        // will not receive these, as she is not a normal participant
        participant.subscribeEventParticipantsMessages(displayParticipantMessageHandler)
      }

      // if the participant began as a spectator, this will stop subscribing to
      // these messages
      await anearEvent.closeSpectatorsChannel()

      // this comes last, after the participant's display handlers are setup completely
      // when the App receives the presence enter event, this will trigger initial
      // display messaging
      await participant.setActionPresence(anearEvent.requiresGeoLocationToParticipate())
    }, [participant, anearEvent, displayParticipantMessageHandler]
  )

  const displaySpectatorMessageHandler = useCallback(
    message => {
      logger.info(`spectator message rcvd: ${message.name}`)
      displayMessageHandler(message)
    }, [displayMessageHandler]
  )

  const setSpectatorDisplayHandlers = useCallback(
    async () => {
      if (anearEvent.spectatorsAllowed()) {
        await anearEvent.subscribeSpectatorDisplayMessages(
          userAuthContainer.anearUser(),
          displaySpectatorMessageHandler
        )
      }
    }, [userAuthContainer, anearEvent, displaySpectatorMessageHandler]
  )

  const setBrowserClicks = useCallback(
    clickHandler => {
      const elems = frameContentRef.current.querySelectorAll(`[${AnearBrowserClick}]`)
      elems.forEach(elem => {
        if (elem.getAttribute(AnearBrowserClick) !== NotClickable) {
          elem.onclick = clickHandler
        }
      })
    }, [frameContentRef]
  )

  const setActionClicks = useCallback(
    handler => {
      const elems = frameContentRef.current.querySelectorAll(`[${AnearActionClick}]`)
      const h = handler ? e => audibleClick(e, handler) : undefined

      elems.forEach(elem => {
        if (elem.getAttribute(AnearActionClick) !== NotClickable) {
          elem.onclick = h
        }
      })
    }, [frameContentRef]
  )

  const setActionFormSubmitClick = useCallback(
    handler => {
      const form = frameContentRef.current.querySelector(`[${AnearActionForm}]`)
      if (!form) return
      const submit = form.querySelector('input[type=submit]')
      submit.onclick = handler
    }, [frameContentRef]
  )

  const clearActionHandlers = useCallback(
    () => {
      setActionClicks(undefined)
      setActionFormSubmitClick(undefined)
      setBrowserClicks(undefined)
    }, [setActionClicks, setActionFormSubmitClick, setBrowserClicks]
  )

  const actionClickMessage = useCallback(
    e => {
      clearActionHandlers()

      const message = e.target.getAttribute(AnearActionClick)
      participant.publishAction(message)
      return false
    }, [clearActionHandlers, participant]
  )

  const actionFormSubmitMessage = useCallback(
    e => {
      clearActionHandlers()

      const form = e.target.closest(`[${AnearActionForm}]`)
      const message = getActionFormMessage(form)

      participant.publishAction(message)

      return false
    }, [clearActionHandlers, participant]
  )

  const participantBrowserClickCommand = useCallback(
    async e => {
      const command = e.target.getAttribute(AnearBrowserClick)

      logger.info(`got ${command} from anear-browser-click`)

      switch(command) {
        case 'back':
        case 'replay':
          await eventClickHandlers(e, command)
          break;
        default:
          throw new Error(`unhandled or invalid command ${command}`)
      }
      return false
    }, [eventClickHandlers]
  )

  const spectatorBrowserClickCommand = useCallback(
    async e => {
      const command = e.target.getAttribute(AnearBrowserClick)

      logger.info(`got ${command} from anear-browser-click`)

      switch(command) {
        case 'back':
        case 'join':
          await eventClickHandlers(e, command)
          break;
        default:
          throw new Error(`unhandled or invalid command ${command}`)
      }
      return false
    }, [eventClickHandlers]
  )

  useEffect(() => {
    // if mounting the EventFrame as a participant, 
    // we want this useEffect to trigger when participant goes from
    // null to AnearParticipant instance, because the user may
    // be transitioning from spectator to participant via JOIN click
    const participantJoinedHandler = async () => {
      if (participant) {
        await setParticipantDisplayHandler()
        setActionClicks(actionClickMessage)
        setBrowserClicks(participantBrowserClickCommand)
        setActionFormSubmitClick(actionFormSubmitMessage)
      } else if (anearEvent.spectatorsAllowed()) {
        anearEvent.initSpectatorMessaging()
        setSpectatorDisplayHandlers()
        setBrowserClicks(spectatorBrowserClickCommand)
      }
    }

    participantJoinedHandler()
  }, [
    participant, // this may change
    anearEvent,  // remainder are stable
    setActionClicks,
    actionClickMessage,
    setBrowserClicks,
    participantBrowserClickCommand,
    spectatorBrowserClickCommand,
    setActionFormSubmitClick,
    actionFormSubmitMessage,
    setSpectatorDisplayHandlers,
    setParticipantDisplayHandler,
  ])

  return (
    <>
      <AppDisplay
        frameContentRef={frameContentRef}
        frameContent={frameContent}
        anearEvent={anearEvent}
        lastMessageId={lastMessageId}
        userAuthContainer={userAuthContainer}
      />
    </>
  )
}

export default EventFrame
