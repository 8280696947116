import React from 'react'
import UIStateContainer from '../Containers/UIStateContainer'
import IconButton from '@mui/material/IconButton'
import ScanQrButton from '../UI/ScanQrButton'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import LinearProgress from '@mui/material/LinearProgress'
import MenuIcon from '@mui/icons-material/Menu'
import AccountMenu from './AccountMenu'
import Grid from '@mui/material/Grid'

const AnearAppsTitle = "Anear Apps"

const Header = () => {
  const uiContainer = UIStateContainer.useContainer()

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="sticky">
        <Toolbar>
          <Grid container alignItems="center" justifyContent="space-between">
            <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
              <IconButton
                sx={{ marginLeft: -1 }}
                color="inherit"
                aria-label="Menu"
                onClick={_ => uiContainer.openBurgerMenu()}
              >
                <MenuIcon />
              </IconButton>
              <ScanQrButton />
            </Grid>
            <Grid item>
              <Typography variant="h6" align="center" color="inherit">
                {AnearAppsTitle}
              </Typography>
            </Grid>
            <Grid item>
              <AccountMenu />
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      {uiContainer.isInProgress && <LinearProgress variant="indeterminate" color="secondary" />}
    </Box>
  )
}
export default Header
