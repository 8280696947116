import React from 'react'
import UserAvatar from './UserAvatar'
import AccountCircle from '@mui/icons-material/AccountCircle'
import UserAuthContainer from '../Containers/UserAuthContainer'

const CurrentUserAvatar = ({size = 'smallAvatar', withName = false}) => {

  const container = UserAuthContainer.useContainer()

  return (
    container.isAuthed() ? (
      <UserAvatar anearUser={container.anearUser()} withName={withName} size={size} />
    ) : (
      <AccountCircle sx={{height: 28, width: 28}} />
    )
  )
}

export default CurrentUserAvatar
