import { useState } from 'react'
import { createContainer } from 'unstated-next'
import anearApi from '../Api/AnearApi'
import * as logger from 'loglevel'
import anearEvents from '../Utils/AnearEvents'
import AnearEventState from '../Utils/AnearEventState'

const useEvents = () => {
  const DEFAULT_PLAYABLES_FILTER = {
    radius: 4000, //meters
  }

  const initialState = {
    globalPlayableApps: [],
    playableEvents: [], // local Events in which a User can choose to Participate
    eventStates: {},
  }

  const [state, setState] = useState(initialState)

  const gset = (newState) => {
    setState((currentState) => ({ ...currentState, ...newState }))
  }

  const loadGlobalPlayableApps = async () => {
    try {
      if (!globalPlayableAppsLoaded()) {
        const apps = await anearApi.globalPlayableApps()
        gset({ globalPlayableApps: apps })
        logger.info('Global playable apps loaded')
      }
    } catch (err) {
      logger.error('Failed to load global playable apps:', err)
    }
  }

  const loadPlayableEvents = async () => {
    try {
      if (!playableEventsLoaded()) {
        const events = await anearApi.playableEvents(DEFAULT_PLAYABLES_FILTER)
        gset({ playableEvents: events })
        logger.info('Playable events loaded')
      }
    } catch (err) {
      logger.error('Failed to load playable events:', err)
    }
  }

  const loadEventFromSlug = async eventSlug => {
    try {
      const anearEvent = await anearApi.getEvent(eventSlug)
      addNewEvent(anearEvent)
      logger.info(`Event loaded from slug ${eventSlug}`)
      return anearEvent
    } catch (error) { 
      logger.error(`API getEvent failed for ${eventSlug}`, error)
      return null
    }
  }

  const getEventFromSlug = eventSlug => {
    return anearEvents.getEventFromSlug(eventSlug)
  }

  const globalPlayableAppsLoaded = () => state.globalPlayableApps.length > 0

  const playableEventsLoaded = () => state.playableEvents.length > 0

  const globalPlayableApps = () => state.globalPlayableApps

  const playableEvents = () => state.playableEvents

  const getEventState = (eventId) => {
    return new AnearEventState(state.eventStates[eventId])
  }

  const isEventLoaded = (eventId) => {
    return anearEvents.isEventLoaded(eventId)
  }

  const isEventLoadedSlug = (eventSlug) => {
    return anearEvents.isEventLoadedSlug(eventSlug)
  }

  const hasParticipantForEvent = (eventId) => {
    return anearEvents.hasParticipantForEvent(eventId)
  }

  const getEvent = (eventId) => {
    return anearEvents.getEvent(eventId)
  }

  const addNewEvent = (anearEvent) => {
    updateEventState(anearEvent)
    anearEvents.addEventAndInitMessaging(anearEvent)
  }

  const deleteEvent = async eventId => {
    await anearEvents.deleteEvent(eventId)
  }

  const addNewParticipant = (anearParticipant) => {
    anearEvents.addParticipantAndInitMessaging(anearParticipant)
  }

  const getParticipantForEvent = eventId => {
    return anearEvents.getParticipantForEvent(eventId)
  }

  const findPlayableAppByZoneId = (zoneId) => {
    return state.globalPlayableApps.find(
      playableApp => playableApp.zone.id === zoneId
    )
  }

  const updateEventState = (anearEvent) => {
    const eventId = anearEvent.id
    const eventStates = { ...state.eventStates }

    if (eventStates.hasOwnProperty(eventId)) delete eventStates[eventId]

    eventStates[eventId] = anearEvent.getEventState()
    gset({ eventStates })
  }

  const needsEventRefresh = (eventId, newState) => {
    const eventState = getEventState(eventId)
    return eventState.needsEventRefresh(newState)
  }

  const setGlobalPlayableApps = (globalPlayableApps) => {
    gset({ globalPlayableApps })
  }

  const setPlayableEvents = (playableEvents) => {
    gset({ playableEvents })
  }

  return {
    state,
    gset,
    loadGlobalPlayableApps,
    loadPlayableEvents,
    loadEventFromSlug,
    globalPlayableAppsLoaded,
    playableEventsLoaded,
    globalPlayableApps,
    playableEvents,
    getEventState,
    isEventLoaded,
    isEventLoadedSlug,
    hasParticipantForEvent,
    getEvent,
    getEventFromSlug,
    getParticipantForEvent,
    addNewEvent,
    deleteEvent,
    addNewParticipant,
    findPlayableAppByZoneId,
    updateEventState,
    needsEventRefresh,
    setGlobalPlayableApps,
    setPlayableEvents,
  }
}

const EventsContainer = createContainer(useEvents)
export default EventsContainer
