import { useEffect } from 'react'
import EventsContainer from '../Containers/EventsContainer'
import UIStateContainer from '../Containers/UIStateContainer'
import * as logger from 'loglevel'
import { useParams } from 'react-router-dom'

const useLoadEvent = () => {
  const eventsContainer = EventsContainer.useContainer()
  const uiState = UIStateContainer.useContainer()
  const { eventSlug } = useParams()

  useEffect(() => {
    const loadEvent = async () => {
      let anearEvent = eventsContainer.getEventFromSlug(eventSlug)
      if (anearEvent) return anearEvent

      try {
        uiState.startProgress()
        return await eventsContainer.loadEventFromSlug(eventSlug)
      } catch (err) {
        logger.error(`Failed to load event with slug ${eventSlug}:`, err)
      } finally {
        uiState.progressComplete()
      }
    }

    loadEvent()
  }, [eventsContainer, eventSlug, uiState])
}

export default useLoadEvent
