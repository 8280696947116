import { useEffect } from 'react'
import UserAuthContainer from '../Containers/UserAuthContainer'
import UIStateContainer from '../Containers/UIStateContainer'
import AuthTokenExpiredError from '../Api//AuthTokenExpiredError'
import * as logger from 'loglevel'
import useNavigation from '../Utils/navigationUtils'

const useLoadUser = () => {
  const userAuth = UserAuthContainer.useContainer()
  const uiState = UIStateContainer.useContainer()
  const { navigateToLogin, navigateToHome } = useNavigation()

  useEffect(() => {
    const loadUser = async () => {
      try {
        if (!userAuth.isAuthed() && !userAuth.isLoggingOut) {
          uiState.startProgress()
          await userAuth.loadUser()
          logger.info('User authentication loaded')
        }
      } catch (err) {
        logger.error('Failed to load user authentication:', err)

        if (err instanceof AuthTokenExpiredError) {
          await userAuth.clearUserAndToken()
          navigateToLogin()
        } else {
          logger.error(err.message)
          navigateToHome()
        }

      } finally {
        uiState.progressComplete()
      }
    }

    loadUser()
  }, [userAuth, uiState, navigateToHome, navigateToLogin])

  return userAuth
}

export default useLoadUser
