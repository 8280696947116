import { useEffect } from 'react'

const AnearQrCode       = 'anear-qr-code'
const AnearUserAvatar   = 'anear-user-avatar'

const useAppDisplayDomSetup = (anearEvent, frameContentRef, userAuthContainer) => {

  useEffect(
    () => {
      const setCurrentUserAvatarInDOM = (elems, userName, avatarUrl) => {
        elems.forEach(img => {
          img.src = avatarUrl
          img.alt = `Avatar for ${userAuthContainer.name()}`
        })
      }
      const setQrCodeImgSrc = (elems, eventName, qrImageUrl) => {
        elems.forEach(img => {
          img.src = qrImageUrl
          img.alt = `QR Code for ${eventName}`
        })
      }
      const avatarUrlElems = frameContentRef.current.querySelectorAll(`[${AnearUserAvatar}]`)
      const anearQrCodeElems = frameContentRef.current.querySelectorAll(`[${AnearQrCode}]`)

      if (avatarUrlElems) setCurrentUserAvatarInDOM(avatarUrlElems, userAuthContainer.name(), userAuthContainer.avatarUrl())
      if (anearQrCodeElems) setQrCodeImgSrc(anearQrCodeElems, anearEvent.name, anearEvent.attributes['qr-image-url'])
    }, [frameContentRef, userAuthContainer, anearEvent]
  )
}

export default useAppDisplayDomSetup
