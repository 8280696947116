import * as log from 'loglevel'
import React, { useState } from 'react';
import {
  Avatar,
  Box,
  Button,
  CssBaseline,
  FormControl,
  IconButton,
  InputAdornment,
  Input,
  InputLabel,
  Paper,
  Typography
} from '@mui/material'
import AssignmentIcon from '@mui/icons-material/Assignment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import anearApi from '../Api/AnearApi'
import FormErrorHelper from '../UI/FormErrorHelper'

import ROUTES from '../../routesConfig'
import UserAuthContainer from '../Containers/UserAuthContainer'
import UIStateContainer from '../Containers/UIStateContainer'
import useNavigation from '../Utils/navigationUtils'


const SignupPage = () => {

  const userAuthContainer = UserAuthContainer.useContainer()
  const uiStateContainer = UIStateContainer.useContainer()
  const {
    getNavigateComponent,
    navigateToProfile,
    navigateToApps,
    navigateToLogin
  } = useNavigation()

  const initName = () => {
    let nm = userAuthContainer.name() || ""
    if (nm.match(/^[Gg]uest/)) {
      nm = ""
    }
    return nm
  }

  const [ name, setName ] = useState(initName())
  const [ email, setEmail ] = useState('')
  const [ password, setPassword ] = useState('')
  const [ disableSubmitButton, setDisableSubmitButton ] = useState(false)
  const [ showPassword, setShowPassword ] = useState(false)
  const [ error, setError ] = useState(null)

  const clearLocalState = () => {
    setName(userAuthContainer.name()||"")
    setEmail("")
    setPassword("")
    setDisableSubmitButton(false)
    setShowPassword(false)
    setError(null)
  }

  const signupSuccess = async (user) => {
    uiStateContainer.progressComplete()
    clearLocalState()
    await userAuthContainer.setUser(user)
    navigateToProfile()
  }

  const signupFailure = (error) => {
    // flash a message in the signup box and outline red
    uiStateContainer.progressComplete()
    log.error(error)
    setDisableSubmitButton(false)
    setError(error)
  }

  const handleSubmit = async (e) => {
    uiStateContainer.startProgress()
    e.preventDefault()

    try {
      setDisableSubmitButton(true)
      const user = await anearApi.register(
        name,
        email,
        password
      )
      await signupSuccess(user)
    } catch(error) {
      signupFailure(error)
    }
  }

  const handleClickShowPassword = () => setShowPassword(!showPassword)

  const guestButtonHandler = async (e) => {
    e.preventDefault()

    try {
      setDisableSubmitButton(true)
      uiStateContainer.startProgress()
      const user = await anearApi.getGuestAccount()
      uiStateContainer.progressComplete()
      await userAuthContainer.setUser(user)
      clearLocalState()
      navigateToApps()
    } catch(errResp) {
      uiStateContainer.progressComplete()
      log.error(errResp)
    }
  }

  const loginButtonHandler = e => {
    e.preventDefault()

    navigateToLogin()
  }

  if (userAuthContainer.isRegisteredAuthed()) return getNavigateComponent(ROUTES.APPS)

  return (
    <main sx={{
      width: 'auto',
      display: 'block', // Fix IE 11 issue.
      ml: 3,
      mr: 3
    }}>
      <CssBaseline />
        <Paper sx={{
          mt: 4,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          p: [2,3,2]
        }}>
        <Avatar sx={{margin: 1}}>
          <AssignmentIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Anear Signup
        </Typography>
        <Box component="form"
          sx={{
            width: '100%', // Fix IE 11 issue.
            mt: 1
          }}
          autoComplete="off">
          <FormControl margin="dense" required fullWidth error={error !== null}>
            <InputLabel htmlFor="name">User Name</InputLabel>
            <Input id="name"
              name="name"
              required autoFocus
              value={name}
              onChange={e => setName(e.target.value)}/>
            <FormErrorHelper error={error} attrName={'name'} />
          </FormControl>
          <FormControl margin="dense" required fullWidth error={error !== null}>
            <InputLabel htmlFor="email">Email Address</InputLabel>
            <Input id="email"
              name="email"
              required
              onChange={e => setEmail(e.target.value)}/>
            <FormErrorHelper error={error} attrName={'email'} />
          </FormControl>
          <FormControl margin="dense" required fullWidth error={error !== null}>
            <InputLabel htmlFor="password">Password</InputLabel>
            <Input name="password"
              type={showPassword ? "text" : "password"}
              id="password" required
              onChange={e => setPassword(e.target.value)}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="Toggle password visibility"
                    onClick={handleClickShowPassword}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
            />
            <FormErrorHelper error={error} attrName={'password'} />
          </FormControl>
          <Button
            sx={{mt: 2}}
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={disableSubmitButton}
            onClick={e => handleSubmit(e)}
          >
            Signup
          </Button>
        </Box>
        <Button
          sx={{mt: 2}}
          size="small"
          color="secondary"
          onClick={e => loginButtonHandler(e)}
        >
          Login
        </Button>
        { !userAuthContainer.isAuthed() && (
            <Button
              sx={{mt: 2}}
              size="small"
              color="secondary"
              onClick={e => guestButtonHandler(e)}
            >
              Continue As Guest
            </Button>
          )
        }
      </Paper>
    </main>
  )
}

export default SignupPage
