import React, { useEffect } from 'react'
import useNavigation from '../Utils/navigationUtils'

const HomePage = _ => {
  const { navigateToApps } = useNavigation()

  useEffect(() => {
    navigateToApps()
  })

  return (
    <>
    </>
  )
}

export default HomePage
