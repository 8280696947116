import React from 'react'
import { Route, Routes } from 'react-router-dom'
import AuthenticatedRoute from './AuthenticatedRoute'
import EventRoute from './EventRoute'
import EventsRoute from './EventsRoute'
import HomePage from './components/Pages/HomePage'
import ScanPage from './components/Pages/ScanPage'
import AboutPage from './components/Pages/AboutPage'
import LoginPage from './components/Pages/LoginPage'
import LogoutPage from './components/Pages/LogoutPage'
import SignupPage from './components/Pages/SignupPage'
import EventsPage from './components/Pages/EventsPage'
import AppsPage from './components/Pages/AppsPage'
import EventPage from './components/Pages/EventPage'
import CreateEventPage from './components/Pages/CreateEventPage'
import EventParticipantPage from './components/Pages/EventParticipantPage'
import ViewProfilePage from './components/Pages/ViewProfilePage'
import UpdateProfilePage from './components/Pages/UpdateProfilePage'
import NearbyPage from './components/Pages/NearbyPage'
import ChatPage from './components/Pages/ChatPage'
import ROUTES from './routesConfig'

const AnearRoutes = () => {
  return (
    <Routes>
      <Route path={ROUTES.HOME}   element={<HomePage />} />
      <Route path={ROUTES.SCAN}   element={<ScanPage />} />
      <Route path={ROUTES.ABOUT}  element={<AboutPage />} />
      <Route path={ROUTES.LOGIN}  element={<LoginPage />} />
      <Route path={ROUTES.LOGOUT} element={<LogoutPage />} />
      <Route path={ROUTES.SIGNUP} element={<SignupPage />} />

      <Route path={ROUTES.APPS}                element={<AuthenticatedRoute element={<AppsPage />} />} />
      <Route path={ROUTES.CREATE_EVENT()}      element={<AuthenticatedRoute element={<CreateEventPage />} />} />
      <Route path={ROUTES.EVENT_PARTICIPANT()} element={<AuthenticatedRoute element={<EventParticipantPage />} />} />
      <Route path={ROUTES.PROFILE}             element={<AuthenticatedRoute element={<ViewProfilePage />} />} />
      <Route path={ROUTES.UPDATE_PROFILE}      element={<AuthenticatedRoute element={<UpdateProfilePage />} />} />
      <Route path={ROUTES.CHAT}                element={<AuthenticatedRoute element={<ChatPage />} />} />

      <Route path={ROUTES.EVENTS}       element={<EventsRoute element={<EventsPage />} />} />
      <Route path={ROUTES.NEARBY}       element={<EventsRoute element={<NearbyPage />} />} />
      <Route path={ROUTES.EVENT_PAGE()} element={<EventRoute  element={<EventPage />} />} />
    </Routes>
  )
}

export default AnearRoutes
