import { useEffect } from 'react';
import UserAuthContainer from '../Containers/UserAuthContainer'
import useNavigation from '../Utils/navigationUtils'
import ROUTES from '../../routesConfig'

const LogoutPage = () => {

  const { navigateToPathWithReplace } = useNavigation()
  const userAuth = UserAuthContainer.useContainer()

  useEffect(() => {

    // Perform any other logout logic, like cleaning up user state if needed
    userAuth.clearUserAndToken()

    // Redirect to login page
    navigateToPathWithReplace(ROUTES.LOGIN)
  }, [userAuth, navigateToPathWithReplace])

  return null // Render nothing
}

export default LogoutPage
