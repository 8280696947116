import React from 'react'
import AuthenticatedRoute from './AuthenticatedRoute'
import useLoadEvent from './components/Hooks/useLoadEvent'

const EventRoute = ({ element }) => {
  // Load event data
  useLoadEvent()

  // Wrap the element with AuthenticatedRoute
  return <AuthenticatedRoute element={element} />
}

export default EventRoute
