import React from 'react'
import AuthenticatedRoute from './AuthenticatedRoute'
import useLoadPlayableEvents from './components/Hooks/useLoadPlayableEvents'

const EventsRoute = ({ element }) => {
  useLoadPlayableEvents()

  // Wrap the element with AuthenticatedRoute
  return <AuthenticatedRoute element={element} />
}

export default EventsRoute
