import { useEffect } from 'react'
import EventsContainer from '../Containers/EventsContainer'
import UIStateContainer from '../Containers/UIStateContainer'
import * as logger from 'loglevel'

const useLoadGlobalPlayableApps = () => {
  const eventsContainer = EventsContainer.useContainer()
  const uiState = UIStateContainer.useContainer()

  useEffect(() => {
    const loadApps = async () => {
      try {
        if (!eventsContainer.globalPlayableAppsLoaded()) {
          uiState.startProgress()
          await eventsContainer.loadGlobalPlayableApps()
        }
      } catch (err) {
        logger.error('Failed to load global playable apps:', err)
      } finally {
        uiState.progressComplete()
      }
    }

    loadApps()
  }, [eventsContainer, uiState])
}

export default useLoadGlobalPlayableApps
