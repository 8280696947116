import { useState, useRef } from 'react'
import { createContainer } from 'unstated-next'

const WAIT_BEFORE_PROGRESS_START = 250; // msecs

const useUIState = () => {
  const initialState = {
    burgerMenuOpen: false,
    inProgress: false,
  }

  const [state, setState] = useState(initialState)
  const timerRef = useRef(null) // Using useRef to persist the timer across renders

  const gset = newState => {
    setState((currentState) => ({ ...currentState, ...newState }))
  }

  const startProgress = () => {
    if (timerRef.current) return // Ensure no duplicate timers
    timerRef.current = setTimeout(() => gset({ inProgress: true }), WAIT_BEFORE_PROGRESS_START)
  }

  const progressComplete = () => {
    if (timerRef.current) {
      clearTimeout(timerRef.current)
      timerRef.current = null // Reset the timer
    }
    if (state.inProgress) { // Only update if inProgress is true
      gset({ inProgress: false })
    }
  }

  const openBurgerMenu = () => {
    gset({ burgerMenuOpen: true })
  }

  const closeBurgerMenu = () => {
    if (state.burgerMenuOpen) gset({ burgerMenuOpen: false })
  }

  return {
    isInProgress: state.inProgress,
    isBurgerMenuOpen: state.burgerMenuOpen,
    startProgress,
    progressComplete,
    openBurgerMenu,
    closeBurgerMenu,
  }
}

const UIStateContainer = createContainer(useUIState)
export default UIStateContainer
