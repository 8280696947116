import React from 'react';
import { Link } from 'react-router-dom'
import CurrentUserAvatar from '../UI/CurrentUserAvatar'
import UserLocationMap from '../Utils/UserLocationMap'
import UserAuthContainer from '../Containers/UserAuthContainer'
import ROUTES from '../../routesConfig'

import {
  Card,
  Box,
  CardActionArea,
  CardActions,
  CardContent,
  Button,
  Typography
} from '@mui/material';

const InitMapZoom = 18
const LinkStyling = {
  textDecoration: 'none'
}

const UserProfileCard = ({ userAuthContainer }) => (
  <>
    <Card
      sx={{
        width: 'auto',
        display: 'block', // Fix IE 11 issue.
        m: 2
      }}
    >
      <CardActionArea>
        <CardContent>
          <Box sx={{margin: "0px auto"}}>
            <Box
              sx={{
                width: "42%",
                float: "left"
              }}
            >
              <CurrentUserAvatar size={'hugeAvatar'} withName />
            </Box>
            <Box
              sx={{
                width: "55%",
                float: "right",
                position: "relative",
                top: -20,
                right: -10,
              }}
            >
              <UserLocationMap userAuthContainer={userAuthContainer} zoom={InitMapZoom}/>
            </Box>
          </Box>
          <Box sx={{clear: 'both'}} />
          <Typography sx={{mt: 3}} gutterBottom variant="h5" component="h2">
            {userAuthContainer.fullName()}
          </Typography>
          <Typography gutterBottom variant="h5" component="h2">
            {userAuthContainer.profile().homepage}
          </Typography>
          <Typography component="p">
            {userAuthContainer.profile().bio}
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions>
        <Box component={Link} sx={LinkStyling} to={ROUTES.UPDATE_PROFILE}>
          <Button size="small" color="primary">
            Edit Profile
          </Button>
        </Box>
      </CardActions>
    </Card>
  </>
)

const GuestProfileCard = ({ userAuthContainer}) => (
  <>
    <Card
      sx={{
        width: 'auto',
        display: 'block', // Fix IE 11 issue.
        m: 2
      }}
    >
      <CardActionArea>
        <CardContent>
          <Box sx={{margin: "0px auto"}}>
            <Box
              sx={{
                width: "42%",
                float: "left"
              }}
            >
              <CurrentUserAvatar size={'hugeAvatar'} withName />
            </Box>
            <Box
              sx={{
                width: "55%",
                float: "right",
                position: "relative",
                top: -20,
                right: -10,
              }}
            >
              <UserLocationMap userAuthContainer={userAuthContainer} zoom={InitMapZoom}/>
            </Box>
          </Box>
          <Box sx={{clear: 'both'}} />
        </CardContent>
      </CardActionArea>
      <CardActions>
        <>
          <Box component={Link} sx={LinkStyling} to={ROUTES.LOGIN}>
            <Button size="small" color="primary">
              Login
            </Button>
          </Box>
          <Box component={Link} sx={LinkStyling} to={ROUTES.SIGNUP}>
            <Button size="small" color="primary">
              Signup
            </Button>
          </Box>
        </>
      </CardActions>
    </Card>
  </>
)

const ProfileCard = ({ userAuthContainer }) => {

  const profileCard = userAuthContainer => {
    if (userAuthContainer.isRegisteredAuthed()) {
      return <UserProfileCard userAuthContainer={userAuthContainer} />
    } else {
      return <GuestProfileCard userAuthContainer={userAuthContainer} />
    }
  }

  return (
    userAuthContainer.isAuthed() && profileCard(userAuthContainer)
  )
}

const ViewProfilePage = () => {
  const userAuthContainer = UserAuthContainer.useContainer()

  return <ProfileCard
    userAuthContainer={userAuthContainer}
  />
}

export default ViewProfilePage
