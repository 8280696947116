import React, { useState } from 'react';
import { Link } from 'react-router-dom'
import CameraDrawer from '../UI/CameraDrawer'
import {
  Box,
  Button,
  CssBaseline,
  FormControl,
  Input,
  InputLabel,
  Paper,
  Typography
} from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import CameraAltIcon from '@mui/icons-material/CameraAlt'

import anearApi from '../Api/AnearApi'
import FormErrorHelper from '../UI/FormErrorHelper'
import CurrentUserAvatar from '../UI/CurrentUserAvatar'

import UserAuthContainer from '../Containers/UserAuthContainer'
import UIStateContainer from '../Containers/UIStateContainer'
import useNavigation from '../Utils/navigationUtils'
import ROUTES from '../../routesConfig'

const UpdateProfilePage = () => {

  const uiStateContainer = UIStateContainer.useContainer()
  const userAuthContainer = UserAuthContainer.useContainer()

  const profile = userAuthContainer.profile()

  const [ firstName, setFirstName ] = useState(profile['first-name']||"")
  const [ lastName, setLastName ] = useState(profile['last-name']||"")
  const [ phone, setPhone ] = useState(profile.phone||"")
  const [ homepage, setHomepage ] = useState(profile.homepage||"")
  const [ bio, setBio ] = useState(profile.bio||"")
  const [ disabledSubmitButton, setDisableSubmitButton ] = useState(false)
  const [ showCamera, setShowCamera ] = useState(false)
  const [ error, setError ] = useState(null)

  const { navigateToProfile } = useNavigation()

  const updateProfile = async () => {
    try {
      const profile = await anearApi.updateProfile(
        userAuthContainer.state.user.profile.id,
        firstName,
        lastName,
        phone,
        homepage,
        bio)
      await updateProfileSuccess(profile)
    } catch(error) {
      updateProfileFailure(error)
    }
  }

  const updateProfileSuccess = async anearUser => {
    await userAuthContainer.setUser(anearUser)
    uiStateContainer.progressComplete()
    setDisableSubmitButton(false)
    navigateToProfile()
  }

  const handleSubmit = async e => {
    e.preventDefault()
    uiStateContainer.startProgress()
    setDisableSubmitButton(true)
    await updateProfile()
  }
  
  const updateProfileFailure = updateError => {
    // flash a message in the signup box and outline red
    uiStateContainer.progressComplete()

    setDisableSubmitButton(false)
    setError(updateError)
  }

  const onPhotoComplete = () => {
    setShowCamera(false)
    setDisableSubmitButton(false)
  }

  const handleChangePhoto = e => {
    setShowCamera(true)
    setDisableSubmitButton(true)
  }

  return (
    <Box
      component="main"
      sx={{
        width: 'auto',
        display: 'block', // Fix IE 11 issue.
        ml: 2,
        mr: 2
      }}
    >
      <CssBaseline />
      <CameraDrawer showCamera={showCamera} onPhotoComplete={onPhotoComplete} />
      <Paper
        sx={{
          mt: 4,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          pt: 2,
          px: 3,
          pb: 3
        }}
      >
        <CurrentUserAvatar size={'bigAvatar'} />
        {userAuthContainer.mobileSafari() && (
          <Button
            sx={{
              pt: 0
            }}
            disabled={disabledSubmitButton}
            size='small'
            color='secondary'
            onClick={e => handleChangePhoto(e)}
          >
            Change
            <CameraAltIcon
              sx={{
                ml: 1
              }}
            />
          </Button>
        )}
        <Typography
           sx={{
             mt: 2
           }}
           component='h1'
           variant='h5'
        >
          {userAuthContainer.name()}'s Profile
        </Typography>
        <Box
          component="form"
          sx={{
            width: '100%', // Fix IE 11 issue.
            mt: 2
          }}
        >
          <FormControl margin='dense' fullWidth error={error !== null}>
            <InputLabel htmlFor='firstName'>First Name</InputLabel>
            <Input id='firstName'
              value={firstName}
              name='firstName'
              autoFocus
              onChange={e => {setFirstName(e.target.value); setError(null)}}/>
              <FormErrorHelper error={error} attrName={'firstName'} />
          </FormControl>
          <FormControl margin='dense' fullWidth error={error !== null}>
            <InputLabel htmlFor='lastName'>Last Name</InputLabel>
            <Input id='lastName'
              name='lastName'
              value={lastName}
              onChange={e => {setLastName(e.target.value); setError(null)}}/>
              <FormErrorHelper error={error} attrName={'lastName'} />
          </FormControl>
          <FormControl margin='dense' fullWidth error={error !== null}>
            <InputLabel htmlFor='phone'>Phone Number</InputLabel>
            <Input id='phone'
              name='phone'
              value={phone}
              onChange={e => {setPhone(e.target.value); setError(null)}}/>
              <FormErrorHelper error={error} attrName={'phone'} />
          </FormControl>
          <FormControl margin='dense' fullWidth error={error !== null}>
            <InputLabel htmlFor='homepage'>Homepage</InputLabel>
            <Input id='homepage'
              name='homepage'
              value={homepage}
              onChange={e => {setHomepage(e.target.value); setError(null)}}/>
              <FormErrorHelper error={error} attrName={'homepage'} />
          </FormControl>
          <FormControl margin='dense' fullWidth error={error !== null}>
            <InputLabel htmlFor='bio'>Bio</InputLabel>
            <Input id='bio'
              name='bio'
              multiline
              rows="5"
              value={bio}
              onChange={e => {setBio(e.target.value); setError(null)}}/>
              <FormErrorHelper error={error} attrName={'bio'} />
          </FormControl>
          <Button
            sx={{
              mt: 2
            }}
            type='submit'
            fullWidth
            variant='contained'
            color='primary'
            disabled={disabledSubmitButton}
            onClick={e => handleSubmit(e)}
          >
            Update Profile
          </Button>
          <Link sx={{textDecoration: 'none'}} to={ROUTES.PROFILE}>
            <Button
              sx={{mt: 2}}
              size='medium'
              color='primary'
            >
              <ArrowBackIcon
                sx={{
                  mr: 1,
                  height: 20,
                  width: 20
                }}
              />
              Back
            </Button>
          </Link>
        </Box>
      </Paper>
    </Box>
  )
}

export default UpdateProfilePage
