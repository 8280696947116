const buildRoutePath = (routeTemplate, params) => {
  let path = routeTemplate

  for (const [key, value] of Object.entries(params)) {
    path = path.replace(`:${key}`, value)
  }

  return path
}

const ROUTES = {
  HOME:              '/',
  SCAN:              '/scan',
  ABOUT:             '/about',
  CHAT:              '/chat',
  LOGIN:             '/login',
  LOGOUT:            '/logout',
  SIGNUP:            '/signup',
  PROFILE:           '/profile',
  UPDATE_PROFILE:    '/update_profile',
  APPS:              '/apps',
  EVENTS:            '/events',
  NEARBY:            '/nearby',
  EVENT_PAGE:        (eventSlug = ':eventSlug') => buildRoutePath('/e/:eventSlug', { eventSlug }),
  CREATE_EVENT:      (zoneId = ':zoneId')       => buildRoutePath('/c/:zoneId', { zoneId }),
  EVENT_PARTICIPANT: (eventId = ':eventId')     => buildRoutePath('/p/:eventId', { eventId }),
}

export default ROUTES
