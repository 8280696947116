import * as log from 'loglevel'
import React, { useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import anearApi from '../Api/AnearApi'
import useNavigation from '../Utils/navigationUtils'

import { Box, CssBaseline, Paper, Button, Typography } from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import EventsContainer from '../Containers/EventsContainer'
import UIStateContainer from '../Containers/UIStateContainer'

const EventParticipantPage = () => {

  const eventsContainer = EventsContainer.useContainer()
  const uiStateContainer = UIStateContainer.useContainer()
  const { getNavigateToEventComponent, navigateToEventPage, navigateToApps } = useNavigation()

  const { eventId } = useParams()
  const location = useLocation()

  const [ disableJoinButton, setDisableJoinButton ] = useState(false)
  const [ error, setError ] = useState(null)

  const eventParticipantFailure = async errMsg => {
    // flash a message in the login box and outline red
    log.error(errMsg)
    await setDisableJoinButton(false)
    await setError(errMsg)
  }

  const handleSubmit = async anearEvent => {
    await setDisableJoinButton(true)

    uiStateContainer.startProgress()

    try {
      const anearParticipant = await anearApi.becomeEventParticipant(anearEvent)
      await eventsContainer.addNewParticipant(anearParticipant)
      uiStateContainer.progressComplete()
      navigateToEventPage(anearEvent.short_url)
    } catch(errMsg) {
      uiStateContainer.progressComplete()
      eventParticipantFailure(errMsg)
    }
  }

  const routeState = location.state
  const isPlayAgain = routeState && routeState.playAgain
  const anearEvent = eventsContainer.getEvent(eventId)
  const eventState = eventsContainer.getEventState(eventId)
  const eventName = anearEvent.attributes.name

  if (anearEvent && (eventsContainer.hasParticipantForEvent(eventId) || eventState.isLive())) {
    // if the client reloads this page, and is already a participant, then we just
    // need to redirect to the EventPage.   Also, non-participants who scan a QR Code for an 
    // event and have to chance to participate can also view the event as a spectator
    return getNavigateToEventComponent(eventId)
  }

  return (
    <Box
      sx={{
        width: 'auto',
        display: 'block',
        alignItems: 'center',
        flexDirection: 'column',
        p: 2
      }}
    >
    {
      anearEvent && (
        <>
          <CssBaseline />
          <Paper
            sx={{
              marginTop: 8,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              p: 2
            }}
          >
            <Box
              sx={{
                mb: 1
              }}
            >
              <img
                sx={{
                  height: "60px",
                  float: "left",
                  mr: 2
                }}
                src={anearEvent.zone.app.attributes['icon-url']}
                alt={anearEvent.zone.app.attributes['short-name']}
              />
              <Typography variant="h6">
                {isPlayAgain ? `Play ${eventName} Again?` : `Ready to Join ${eventName}?`}
              </Typography>
            </Box>
            <Typography variant="subtitle2">
              {anearEvent.attributes.description}
            </Typography>
            {error  && (
              <Box
                sx={{
                  mt: 1 
                }}
              >
                <Typography variant="body2" color="error">
                  {error.message}
                </Typography>
              </Box>
            )}
            <Box >
              <Button
                sx={{
                  mt: 3
                }}
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                disabled={disableJoinButton}
                onClick={() => handleSubmit(anearEvent)}
              >
                {isPlayAgain ? "Yes" : "Join Event" }
              </Button>

              <Button
                sx={{mt: 1}}
                size="medium"
                color="primary"
                onClick={() => navigateToApps() }
              >
                <ArrowBackIcon
                  sx={{
                    mr: 1,
                    height: 20,
                    width: 20
                  }}
                />
                  {"Exit"}
              </Button>
            </Box>
          </Paper>
        </>
      )
    }
    </Box>
  )
}

export default EventParticipantPage
