import * as log from 'loglevel'
import React from 'react';
import UIStateContainer from '../Containers/UIStateContainer'
import UserAuthContainer from '../Containers/UserAuthContainer'
import SwipeableDrawer from '@mui/material/SwipeableDrawer'
import Box from '@mui/material/Box'
import Camera, { FACING_MODES, IMAGE_TYPES } from 'react-html5-camera-photo'
import anearApi from '../Api/AnearApi'
import 'react-html5-camera-photo/build/css/index.css';

const CameraSettings = {
  idealFacingMode: FACING_MODES.USER,
  sizeFactor: 0.40,
  imageType: IMAGE_TYPES.JPG,
  imageCompression: 0.97,
}

const CameraDrawer = ({ onPhotoComplete, showCamera }) => {

  const uiContainer = UIStateContainer.useContainer()
  const userAuthContainer = UserAuthContainer.useContainer()

  const onTakePhoto = async (dataURI) => {
    uiContainer.startProgress()
    await updateAvatar(dataURI)
    onPhotoComplete()
  }

  const updateAvatar = async (dataURI) => {
    try {
      const newAnearUser = await anearApi.updateAvatarImage(userAuthContainer.anearUser(), dataURI)
      await userAuthContainer.setUser(newAnearUser)
      uiContainer.progressComplete()
    } catch(error) {
      uiContainer.progressComplete()
      log.error(error)
    }
  }

  return (
    <SwipeableDrawer
      anchor="top"
      open={showCamera}
      onClose={()=>{}}
      onOpen={()=>{}}
    >
      <Box>
        <Camera
          onTakePhoto={(dataURI) => onTakePhoto(dataURI)}
          idealFacingMode={CameraSettings.idealFacingMode}
          sizeFactor={CameraSettings.sizeFactor}
          imageType={CameraSettings.imageType}
          imageCompression={CameraSettings.imageCompression}
        />
      </Box>
    </SwipeableDrawer>
  )
}

export default CameraDrawer
