import { useEffect } from 'react'
import EventsContainer from '../Containers/EventsContainer'
import UIStateContainer from '../Containers/UIStateContainer'
import * as logger from 'loglevel'

const useLoadPlayableEvents = () => {
  const events = EventsContainer.useContainer()
  const uiState = UIStateContainer.useContainer()

  useEffect(() => {
    const loadEvents = async () => {
      try {
        if (!events.playableEventsLoaded()) {
          uiState.startProgress()
          await events.loadPlayableEvents()
        }
      } catch (err) {
        logger.error('Failed to load playable events:', err)
      } finally {
        uiState.progressComplete()
      }
    }

    loadEvents()
  }, [events, uiState])

  return events
}

export default useLoadPlayableEvents
