import React from 'react'
import { Link } from 'react-router-dom'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import Box from '@mui/material/Box'
import AppCard from '../UI/AppCard'
import EventsContainer from '../Containers/EventsContainer'
import useLoadGlobalPlayableApps from '../Hooks/useLoadGlobalPlayableApps'
import ROUTES from '../../routesConfig'

const AppList = ({ apps }) => {

  const appLink = playableApp => (
    <Link sx={{textDecoration: 'none'}} to={ROUTES.CREATE_EVENT(playableApp.zone.id)} key={playableApp.app.id} >
      <ListItem
         sx={{
           pt: 1,
           pb: 1
         }}
      >
        <AppCard playableApp={playableApp} />
      </ListItem>
    </Link>
  )

  return (
    <Box
      sx={{
        width: 'auto',
        display: 'block', // Fix IE 11 issue.
        ml: 1,
        mr: 1,
        maxHeight: 620,
        overflow: "auto",
      }}
    >
      <List dense={true}>
        {apps.map(appLink)}
      </List>
    </Box>
  )
}

const AppsPage = () => {
  useLoadGlobalPlayableApps()

  const eventsContainer = EventsContainer.useContainer()

  if (!eventsContainer.globalPlayableAppsLoaded()) return ""

  return (
    <>
      <AppList apps={eventsContainer.globalPlayableApps()}/>
    </>
  )
}

export default AppsPage
