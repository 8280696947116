import React from 'react'
import { Link } from 'react-router-dom'
import { List, ListItem, Typography, Box } from '@mui/material'

import EventCard from '../UI/EventCard'
import EventsContainer from '../Containers/EventsContainer'
import ROUTES from '../../routesConfig'

const EventList = ({ playableEvents }) => {
  const eventLinks = playableEvents => {
    return (
      playableEvents.map(playableEvent => {
        const anearEvent = playableEvent.anearEvent
        return (
          <Link
            to={ROUTES.EVENT_PAGE(anearEvent.slug)}
            key={anearEvent.id}
            sx={{
              textDecoration: 'none'
            }}
          >
            <ListItem>
              <EventCard playableEvent={playableEvent} />
            </ListItem>
          </Link>
        )
      })
    )
  }

  return (
    <Box
      component="main"
      sx={{
        width: 'auto',
        display: 'block', // Fix IE 11 issue.
        ml: 1,
        mr: 1
      }}
    >
      <List dense={true}>
        {eventLinks(playableEvents)}
      </List>
    </Box>
  )
}

const NearbyPage = () => {
  const eventsContainer = EventsContainer.useContainer()
  const playableEvents = eventsContainer.playableEvents

  return (
    playableEvents.length > 0 ? (
      <EventList playableEvents={playableEvents}/>
    ) : (
      <Typography
        variant="h6"
        sx={{
          textAlign: "center",
          position: "relative",
          top: 50
        }}
      >
        {`There are no Nearby Events`}
      </Typography>
    )
  )
}

export default NearbyPage
