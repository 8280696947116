import * as logger from 'loglevel'
import Ably from 'ably'
import ApiService from '../Api/ApiService'
import { decode } from '@ably/vcdiff-decoder'

const ActionMessageType = 'ACTION'
const ExitEventMessageType = 'exit_event'

const AblyLogLevel = 0 // 0 - no logging, 4 - verbose logging

class UserMessaging {
  constructor() {
    this.api = new ApiService()
    this.realtime = null
  }

  initRealtime = () => {
    const baseUrl = this.api.api_base_url
    const authUrl = `${baseUrl}/messaging_auth`
    const clientOptions = {
      authUrl: authUrl,
      authHeaders: this.anearAuthHeaders(),
      echoMessages: false,
      log: {level: AblyLogLevel},
      plugins: {
         vcdiff: decode
      }
    }
    this.realtime = new Ably.Realtime(clientOptions)
  }

  closeRealtime = () => {
    //
    // close out messaging for ALL events on device
    // initRealtime() must be invoked again to communicate 
    // with Events
    //
    if (this.realtime) {
      this.realtime.close()
      this.realtime = null
    }
  }

  getChannel = (channelName) => {
    const channelOptions = {
      delta: 'vcdiff',
    }

    return this.realtime.channels.get(channelName, channelOptions)
  }

  setChannelPresence = async (presenceChannel, id, requiresGeoLocation = false) => {
    //
    // notifies the App that a participant is joining or a spectator is viewing
    // and optionally establishes geoLocation with the App
    // (for apps that require it and the user has consented)
    // id is the participantId or spectator userId
    //
    const geoLocation = requiresGeoLocation ? await this.api.getPosition() : {}

    logger.info(`setChannelPresence(${presenceChannel.name}, ${id})`)

    presenceChannel.presence.enter(
      { id, geoLocation },
      err => {
        if (err) {
          logger.error(`presence enter failed: ${err}`)
        }
      }
    )
  }

  updateChannelPresence = async (presenceChannel, participantId, requiresGeoLocation) => {
    //
    // method is invoked when participant geoLocation changes, and only when required and
    // by the app and permission is granted by user
    //
    const geoLocation = requiresGeoLocation ? await this.api.getPosition() : {}

    presenceChannel.update(
      { id: participantId, geoLocation },
      (err) => {
        if (err) {
          logger.error(`presence update failed: ${err}`)
        }
      }
    )
  }

  anearAuthHeaders = () => {
    return {
      ...this.api.defaultHeaderObject,
      Authorization: this.api.getAuthorizationBearer(),
    }
  }

  subscribeEventMessages = (channel, messageType, callback, withHistory=true) => {
    channel && channel.attach((err) => {
      if (err) {
        logger.error(`attach failed: ${err}`)
      } else {
        channel.subscribe(messageType, callback)
        logger.info(`subscribed to ${messageType} on channel ${channel.name}`)

        if (withHistory) this.checkMessageHistory(channel, messageType, callback)
      }
    })
  }

  unSubscribeEventMessages = (channel, messageType) => {
    if (!channel) return

    logger.info(`unsubscribe ${channel.name}: ${messageType}`)
    channel.unsubscribe(messageType)
  }

  checkMessageHistory = (channel, messageType, callback) => {
    channel.history(
      {untilAttach: true},
      async (err, resultPage) => {
        if (err) {
          logger.error('Unable to get channel history; err = ' + err.message)
        } else {
          const numItems = resultPage.items.length
          if (numItems > 0) {
            const messages = resultPage.items.filter(message => message.name === messageType)
            if (messages.length > 0) {
              logger.info(`${messages.length} ${messageType} history events received in page.  processing most recent only`)
              await callback(messages[0])
            }
          }
        }
      }
    )
  }
 
  publishAction = async (channel, message) => {
    try {
      await this.publishMessage(channel, ActionMessageType, message)
      logger.info("published Action: ", message)
    } catch(err) {
      logger.error(err.message)
    }
  }

  publishExitEvent = async (channel, message) => {
    try {
      await this.publishMessage(channel, ExitEventMessageType, message)
      logger.info(`published ExitEventMessage from ${message.participantId}`)
    } catch(err) {
      logger.error(err.message)
    }
  }

  publishMessage = (channel, messageType, message) => {
    return new Promise(
      (resolve, reject) => {
        channel.publish(messageType, message, (err) => {
          if (err) {
            reject(err)
          } else {
            resolve()
          }
        })
      }
    )
  }

  detachChannel = (channel) => {
    return new Promise(
      (resolve, reject) => {
        if (!channel) {
          reject('channel is null')
        } else {
          if (channel.state !== 'attached') {
            resolve()
          } else {
            channel.detach((err) => {
              if (err) {
                reject(err)
              } else {
                logger.info(`${channel.name} has been detached`)
                resolve()
              }
            })
          }
        }
      }
    )
  }
}

// Create a singleton instance of UserMessaging
const userMessagingInstance = new UserMessaging();

export default userMessagingInstance;
